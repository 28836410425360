import React from "react"
import { FaCode, FaEdit } from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "web development",
    text: `I can develop web sites and single page web apps. These can be built using React or using basic HTML and CSS`,
  },
  {
    id: 2,
    icon: <FaEdit className="service-icon" />,
    title: "Content Writing",
    text: `I can do content writing for blogs and web sites for SEO purposes.`,
  },
]
